<template>
	<div class="page_box">
		<div class="bom_box">
			<div class="bom_btn_flex">
				<div class="lefts">
					{{ page_title }}
				</div>
				<div class="rights">
					<div class="btns" @click="do_add()">
						新增节假日
					</div>
				</div>
			</div>
			<div class="bom_box_list">
				<el-table :data="list" stripe style="width: 100%" header-cell-class-name="el_table_title"
					@selection-change="handle_list_change" v-loading="loading" height="570">
					<!-- 暂时无需 -->
					<!-- <el-table-column type="selection" width="55"></el-table-column> -->

					<el-table-column prop="name" label="节假日名称" width="">
						<template slot-scope="scope">
						<!-- 	<el-popover trigger="hover" placement="top" :content="scope.row.title">
								<div slot="reference" class="name-wrapper" style="color: #2373C8;">
									{{ scope.row.title }}
								</div>
						</el-popover> -->
						<div slot="reference" class="name-wrapper" style="color: #2373C8;">
							{{ scope.row.title }}
						</div>
						</template>
					</el-table-column>
					<el-table-column prop="startDay" label="开始时间"></el-table-column>
					<el-table-column prop="duration" label="持续时间(天)"></el-table-column>
					<!-- 操作模块   -->
					<el-table-column prop="" label="操作" width="150">
						<template slot-scope="scope">
							<!--<el-popover trigger="hover" placement="top">
								<div slot="reference" class="name-wrapper" >
									编辑
								</div>
							</el-popover> -->
							<el-button @click="do_row_edit(scope.row)" type="text" style="color: #2373C8;" size="small">编辑</el-button>
							<el-button @click="do_row_delete(scope.row)" type="text" style="color: #2373C8;" size="small">删除</el-button>
						</template>

					</el-table-column>
				</el-table>
				<div class="pages">
					<page :pageson='count' @row="row" @currentPage="currentPage"></page>
				</div>
			</div>
		</div>


		<add_jieri ref="add_jieri" @confirm="confirm_add_jieri"></add_jieri>

	</div>
</template>
<script>
	import page from "@/components/page/page.vue"; //分页  统一的

	import add_jieri from "@/components/kaoqin/add_jieri.vue"; //新增节日
	export default {
		components: {
			page,
			add_jieri
		},
		data() {
			return {
				loading: false, //加载总
				page_title: '', //页面名称  来之路由
				//提交数据
				forms: {
					keyword: '',
				},
				list: [ ],
				pages: {
					page: 1,
					limit: 10,
				},
				count: 0,
				times: [], //时间范围
				item_row: {}
			};
		},
		computed: {},
		watch: {},
		created() {
			this.page_title = this.$route.meta.title
			this.get_list()
		},
		mounted() {

		},

		methods: {
			do_add() {
				this.$refs.add_jieri.init()
			},
			confirm_add_jieri() {
				this.pages.page = 1
				this.get_list()
			},

			do_row_edit(row) {
				console.log("删除")
					this.$refs.add_jieri.init(row)
			},		
			do_row_delete(row) {
				this.$confirm('此操作将永久删除该用户, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$api("kaoqin/delHoliday", {
						id: row.id
					}, "post").then((res) => {
						alert(res)
						if (res.code == 200) {
							this.get_list()
						}
					});
				}).catch(() => {
				
				});
			},
			//接受条数
			currentPage(page) {
				console.log(page)
				this.pages.page = page
				this.get_list()
			},
			// 接收条数
			row(row) {
				console.log(row)
				this.pages.limit = row
				this.get_list()
			},
			//选择
			handle_list_change(e) {
				console.log('多选', e)
				this.check_list = e
			},
			//搜素
			handle_search() {
				this.pages.page = 1
				this.get_list()
			},
			//表单回调 触发重新渲染数据
			handle_get_list() {
				this.get_list()
			},
			//统一的列表接口
			get_list() {
				console.log(this.forms)
				this.loading = true
				this.$api("kaoqin/getHolidayList", {
					...this.forms,
					...this.pages,
				}, "get").then((res) => {
					this.loading = false
					if (res.code == 200) {
						this.count = res.data.count
						this.list = res.data.list
					} else {
						alertErr(res.msg)
					}
				});
			},

		},


	};
</script>

<style scoped lang="less">
	.page_box {
		height: 100%;

		// background-color: red;
		.top_box {
			width: 100%;
			// height: 80px;
			background: #FFFFFF;
			border-radius: 8px 8px 8px 8px;
			padding-bottom: 29px;

			.top_title {
				padding: 10px 17px;
				font-family: Microsoft YaHei, Microsoft YaHei;
				font-weight: bold;
				font-size: 14px;
				color: #333333;
			}

			.top_flex {
				display: flex;
				align-items: center;
				// justify-content: space-between;
				flex-wrap: wrap;
				padding: 0 37px;

				.search_flex {
					// width: 25%;
					display: flex;
					align-items: center;
					padding-right: 35px;

					.texts {
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						font-size: 14px;
						color: #333333;
						white-space: nowrap;
						padding-right: 12px;
					}

					.inputs_box {
						flex: 1;

						input {
							width: 236px;
							height: 40px;
							background: #FFFFFF;
							border-radius: 0px 0px 0px 0px;
							border: 1px solid #E8E8E8;
							padding: 0 9px;
							font-family: Microsoft YaHei, Microsoft YaHei;
							font-weight: 400;
							font-size: 14px;
							color: #747474;

						}
					}


					.btn_ends {
						width: 80px;
						height: 40px;
						background: linear-gradient(90deg, #157DE9 0%, #3697FD 100%);
						border-radius: 2px 2px 2px 2px;
						text-align: center;
						line-height: 40px;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						font-size: 14px;
						color: #FFFFFF;
						cursor: pointer;

						&:hover {
							background: linear-gradient(90deg, #0564ca 0%, #3697FD 100%);

						}
					}

				}
			}




		}

		.bom_box {
			background: #FFFFFF;

			.bom_btn_flex {
				display: flex;
				align-items: center;
				justify-content: space-between;
				width: 100%;
				height: 51px;
				background: #FFFFFF;
				box-shadow: 0px 3px 8px 1px rgba(0, 0, 0, 0.06);
				border-radius: 4px 4px 4px 4px;
				margin-top: 20px;
				padding: 0 20px;

				.lefts {
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: bold;
					font-size: 14px;
					color: #333333;
				}

				.rights {
					display: flex;
					align-items: center;

					.btns {
						background: linear-gradient(90deg, #157DE9 0%, #3697FD 100%);
						padding: 6px 13px;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						font-size: 14px;
						color: #FFFFFF;
						cursor: pointer;

						&:hover {
							background: linear-gradient(90deg, #0564ca 0%, #3697FD 100%);

						}
					}

				}
			}

			.bom_box_list {
				width: 1640px;
				height: 670px;
				padding: 20px 20px 32px 20px;

				.pages {
					padding-top: 30px;
				}
			}

		}
	}
</style>